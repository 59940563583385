<template>
  <div>
    <!--------------------- loading --------------------->
    <div v-if="isLoading" key="loading" class="text-center loading-container">
      <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
      <p class="mt-3">Loading watches...</p>
    </div>
    <!--------------------- error --------------------->
    <div v-if="showError" key="error" class="jcard-content text-center">
      <h5 class="mb-3">
        <i class="fad fa-exclamation-circle text-blue fa-3x"></i><br />
      </h5>
      <p>
        Unable to fetch watches from our servers at the moment.
      </p>
    </div>

    <!--------------------- section --------------------->
    <section v-if="!isLoading && !showError" key="content" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div>
          <h3><i class="fal fa-watch"></i> Watches</h3>
        </div>
        <div class="text-right mb-3">
          <router-link class="btn btn-outline-primary" to="create-watch">
            <i class="fa fa-plus"></i> Add Watch
          </router-link>
        </div>
        <!-- Search -->
        <SearchAndFilters class="d-flex mb-3" :searchList=watchesList></SearchAndFilters>

        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th scope="col">Model</th>
              <th scope="col">Owner</th>
              <th scope="col">Expiry</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(watch, index) in watchesList" :key="watch.id">
              <td>{{ watch.id.substring(0, 8) }}</td>
              <td>{{ watchModelsMap.get(watch.modelId).name }}</td>
              <td>{{ watch.userName }}</td>
              <td>{{ watch.expiryString }}</td>
              <td>
                <span class="badge badge-success text-uppercase">{{
                    $C.STATUS.STATUS_INDEX[watch.status]
                }}</span>
              </td>
              <td>
                <button class="jbtn jbtn-icon jbtn-blue" @click="openWatch(index)">
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import SearchAndFilters from "@/components/general/SearchAndFilters"
import C from "@/const";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "WatchesList",
  components: {
    SearchAndFilters
  },
  data() {
    return {
      isLoading: true,
      showError: false,

      watchListReady: false,
      watchModelMapReady: false,
      userMapReady: false,

      watchesList: [],
      watchModelsMap: new Map(),
      usersMap: new Map(),
    };
  },
  methods: {
    prepareSearchData: function () {
      if (!this.watchListReady || !this.watchModelMapReady || !this.userMapReady)
        return;

      console.log(this.watchesList);
      this.isLoading = false;

      this.watchesList.forEach((watch) => {
        const user = this.usersMap.get(watch.userId);
        const watchModel = this.watchModelsMap.get(watch.modelId);
        watch.userName = user ? (user.firstName + " " + user.lastName) : "-";
        watch.modelName = watchModel ? watchModel.name : "-";
        if (watch.expiry)
          watch.expiryString = moment(C.getDateFromTimestamp(watch.expiry)).format("DD-MM-YYYY");
        else {
          const activeWarranty = watch.warranties[watch.warrantyActive].end;
          watch.expiryString = moment(C.getDateFromTimestamp(activeWarranty)).format("DD-MM-YYYY");
        }

        watch.statusName = C.STATUS.STATUS_INDEX[watch.status];
      });
    },
    // TODO: try to only load those that we need
    // so far we load everything for the sake of simplicity before seting up search
    // (search could be set up before data is ready though, since it works with references)
    allWatches: function () {
      ///TODO: add listener
      db.collection(C.COLLECTION.WATCHES)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const watch = doc.data();
            watch.id = doc.id;
            this.watchesList.push(watch);
          });
          this.watchListReady = true;
          this.prepareSearchData();
        }).catch((error) => {
          this.isLoading = false;
          this.showError = true;
          console.log("Error getting watches:", error);
        });
    },
    allWatchModels: function () {
      this.isLoading = true;
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const watchModel = doc.data();
            watchModel.id = doc.id;
            this.watchModelsMap.set(doc.id, watchModel);
          });
          this.watchModelMapReady = true;
          this.prepareSearchData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.showError = true;
          console.log("Error getting watch models: ", error);
        });
    },
    allUsers: function () {
      db.collection(C.COLLECTION.USERS)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const user = doc.data();
            user.id = doc.id;
            this.usersMap.set(doc.id, user);
          });
          this.userMapReady = true;
          this.prepareSearchData();
        }).catch((error) => {
          this.isLoading = false;
          this.showError = true;
          console.log("Error getting users: ", error);
        });
    },
    openWatch(index) {
      //TODO: add watch and model to store
      this.$router.push({
        name: "Watch",
        params: { id: this.watchesList[index].id },
      });
    },
  },
  mounted() {
    this.allWatchModels();
    this.allWatches();
    this.allUsers();
  },
};
</script>

<style scoped>

</style>
